.page-link {
	color: rgb(110, 110, 110) !important;
	border-color: rgb(180, 180, 180) !important;
}

table.table tr,
td,
th {
	font-size: 14px !important;
	color: #333333;
}
