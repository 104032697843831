.CardWithTabs {
	border-radius: 5px 5px 0px 0px;
	display: block !important;

}

.cardtabs-subtitle {
	display: block;
	margin-top: 5px;
	margin-bottom: 15px;
	padding-bottom: 5px;
	border-bottom: 2px solid var(--primary);
	font-size: 16px;
	font-weight: bold;
	color: var(--primary);
}

.card-tab-body {
	// box-shadow: inset 0px 4px 3px -1px var(--primary);
}

.card-header-tab-container {
	display: flex;
	a {
		color: var(--dark-light);
	}

	width: 100%;
	.card-header-tab-item {
		padding: 12px;
		padding-left: 18px;
		padding-right: 18px;
		font-size: 16px;
		cursor: pointer;
		opacity: 0.8;
		border-radius: 5px 5px 0px 0px;
		border-top: 2px solid var(--secondary);
		border-right: 1px solid #d6d9df;
		background-color: var(--secondary);

		flex-shrink: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		&.disabled {
			background-color: #999999;
			color: white;
			pointer-events: none;
		}

		&.card-header-tab-item-active {
			background-color: white;
			color: var(--primary);
			opacity: 1;
			border-top: 2px solid var(--primary);
			font-weight: bold;
			.tab-title {
				display: inline !important;
			}
			flex-shrink: 0;
		}

		&:hover {
			background-color: white;
			color: var(--primary);
			opacity: 1;
			border-top: 2px solid var(--secondary);
		}
	}
}
