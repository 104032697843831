@media print {
	.facturation-logo > img {
		height: 100px !important;
		width: 100px !important;
	}

	#myDivToPrint {
		background-color: white;
		height: 100%;
		width: 100%;
		position: fixed;
		color: black;
		top: 0;
		left: 0;
		margin: 0;
		padding: 100px 100px 100px 100px;
		font-size: 20px;
		line-height: 18px;
	}
}

.btn-print {
	cursor: pointer;
}

.download-button {
	cursor: pointer !important;
	a {
		color: white;
		&:hover {
			color: white;
		}
	}
}

.general-informations,
.facuration-header,
.facturation-amount {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.facturation-logo > img {
	height: 100px;
	width: 100px;
}

.laboratory-informations {
	width: 30vw;
	line-height: 1.5;
}

.patient-informations {
	width: 30vw;
	line-height: 1.5;
}

.invoice {
	color:black;
}
.card-facturation,
.facturation-amount {
	color: black;

	table {
		border-collapse: collapse;
		width: 100%;
		border: 1px solid black;
		margin: 20px 0 20px 0;

		thead,
		#thead {
			border: 1px solid black;
			background: white;
			color: black;
			text-align: center;
			font-weight: bold;
		}

		.table-name-col,
		thead,
		#thead {
			text-align: center;
			font-weight: bold;
		}

		td {
			padding: 10px;
			border: 1px solid black;
		}

		.emptyTD {
			background: linear-gradient(0.25turn, rgba(#16b9a1, 0.2), rgba(#19acba, 0.2));
		}
	}

	table:last-child {
		margin: 20px 0 50px 0;
	}
}

.facuration-footer {
	text-align: center;
	word-spacing: 0.25rem;
}

.vw-20 {
	width:20vw;
}
.facturation-supl-invoiceid {
	font-size: 16px  !important;
	opacity: 0.5;
	font-weight: normal;
}


#button-print-fiche-patient {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	color: white;
	text-align: center;
	padding-top: 5px;
	background-color: #16b9a1;

	:hover {
		cursor: pointer;
	}
}

#custom-button-trash {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	text-align: center;
	padding-top: 13px;
}

.displayFlex{
	display: flex;
	width:100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 25px;
}