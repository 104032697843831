
.state-ok td{
	color: #18bc9c !important;
	// background-color: #e0fcd799;
	// &:hover td{
	// 	background-color: #e5f8de !important;
	// }
}
.state-difference td{
	color: var(--danger) !important;
	// background-color: #fcded799;
	// &:hover td{
	// 	background-color: #fce5e0 !important;
	// }
}

.state-done {
	background-color: var(--secondary);
	&:hover td{
		background-color: var(--secondary) !important;
	}
}
