
.laboratory-infos {
	display: flex;
	width: 100%;
	height: 180px;
	justify-content: space-between; }

.stocktransfert-selector {
	background-color: #f0f0f0;
	padding: 16px;
	border-radius: 5px;
	border: 1px solid #dfdfdf;
	margin-bottom: 16px; }

.stocktransfert-line {
	height: 62px;
	border-radius: 5px;
	align-items: center;
	&:nth-child(even) {
		background: darken(#f0f0f0, 4%); } }

.stockTransfert-table {
	border: 1px solid black;
	background-color: red; }
