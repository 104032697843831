.appointment-arrival {
	position: absolute;
	right: 8px;
	display: inline-block;
	transform: rotate(45deg);
	height: 18px;
	width: 10px;
	border-bottom: 4px solid green;
	border-right: 4px solid green;
}

.hidebar {
	height: 120px;
	width: 19px;
	position: absolute;
	left: 0px;
	cursor: pointer;
	border-radius: 5px;
	z-index: 200;
	display: flex;
	align-items: center;
	color: #18bc9c;
	border: 1px solid #18bc9c;
	transition: all .2s ;
	&:hover {
		background-color: #18bc9c;
		color: #fff;
	}
}