.ck.ck-toolbar {
	background-color: var(--primary-light);
	border: 1px solid var(--primary-light);
}

.ck-editor__editable {
	border: 1px solid $success !important;
	height: auto;
	width: 95%;
	align-self: center;
}

.ck.ck-button {
	&:hover {
		background-color: var(--primary) !important;
	}

	&.ck-on {
		background-color: var(--primary);
	}
}

.ck.ck-list__item .ck-button.ck-on {
	background-color: var(--primary-light);
	color: black;
}

.ck.ck-content {
	border: solid;
	padding: 1%;
	margin-top: 1%;
}

@media screen and (min-width: 1024px) {
	.ck-editor__editable {
		width: 60%;
	}
	.editor-decouverte {
		.ck-editor__editable {
			width: 100%;
		}
	}
}
