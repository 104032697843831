// .Toastify__toast--info {
// 	background: $info;
// }

// .Toastify__toast--success {
// 	background: $success;
// }

// .Toastify__toast--warning {
// 	background: $warning;
// }

// .Toastify__toast--danger {
// 	background: $danger;
// }

.Toastify__toast-container{
	min-width: 450px;
}

	// .Toastify__toast-container
// 	width: 450px
// .Toastify__toast--warning, .Toastify__close-button--warning
// 	color: #4e3f00