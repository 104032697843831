.custom-phone-input .react-tel-input {
    .form-control {
      width: 100%;
      height: calc(1.5em + 1.5rem + 2px);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #8492a6;
      border: 1px solid #e0e6ed;
    }
  
    .flag-dropdown {
      border: 1px solid #e0e6ed;
    }
  
    .form-control:focus {
      border-color: rgba(24, 188, 156, 0.5);
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075),
        0 0 20px rgba(24, 188, 156, 0.1);
    }

    // Pour éviter les conflits avec le css de purpose
    .highlight { 
        margin-bottom: unset;
        border:unset;
        border-radius:  unset;
    }
  }
  