// Buttons previous and next
.direction-container {
    margin-left:5px!important;
}

.direction-btn-container {
    height: 4rem;
    margin-bottom: 20px;
    margin-left:0!important;
}

.col-previous-btn,
.col-next-btn {
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-salle-attente {
    width: 120px;
    height: 100%;
}

.btn-salle-attente {
    justify-content:center;

}

.next-btn {
    text-align:center;
}

// Card headers 
.card-header-salle-attente,
.card-header-identite,
.card-header-situations {
    border-bottom: 1px solid #eff2f7;
    margin-top: 10px;
}


// Titles

.premiere-visite-title {
    font-size: 2rem;
    text-align:left;
}

.premiere-visite-header {
    font-size: 2rem;
    text-align:center;
}

// Others
.situation-yes-no-container {
    height: 100px;
}

.yes-no-btn {
    height:100%;
    font-size: 1.5em!important;
}

// Media queries

@media (max-width: 576px) {
    .direction-container {
        flex-direction:column;
    }

    .direction-btn-container {
        align-items:start;
        margin: 0 0 40px !important;
        padding: 0 5px;
    }

    .card-header-salle-attente {
        height: 80px;
        margin-bottom:2em;
        margin-top: 20px;
        padding-bottom: 100px;
    }

    .btn-salle-attente {
        padding:0!important;
        width: 100px;
    }

    .card-header-identite {
        padding-bottom: 50px;
        height: 110px;
    }

    .card-header-situations {
        height: 200px;
        margin-bottom:2em;
        margin-top: 0;
        padding-bottom: 100px;
    }

    .col-previous-btn,
    .col-next-btn  {
        margin: 0;
        padding:0!important;
    }

    .col-previous-btn > div {
        margin-left: 0.1rem
    }

    .btn-header-next {
        margin-left:5px!important;
    }

    .yes-no-btn {
        font-size: 1.3em!important;
    }
}

@media (min-width: 577px) and (max-width:767px) {
    .card-header-identite
     {
        height: 80px;
        }

    .card-header-salle-attente,
    .card-header-situations {
        height: 100px;
    }
}

@media (min-width:768px) {
    .card-header-identite,
    .card-header-salle-attente,
    .card-header-situations {
        height: 80px;
        }
}

@media (max-width: 720px) {
    .premiere-visite-title,
    .premiere-visite-header {
    font-size: 1.2rem;
}
}

.ml-custom {
    margin-left: 1.25rem;
}