//.finEvt-relancegroup
//	height: 14px
//	overflow: hidden
//	transition: all .5s
//
//.finEvt-relancegroup-show
//	height: 142px
//
//
//.finEvt-relancegroup-date
//	height: 0px
//	overflow: hidden
//	transition: all .5s
//
//.finEvt-relancegroup-date-show
//	height: 64px
//
//
//.finEvt-relancegroup-ok
//	height: 0px
//	overflow: hidden
//	transition: all .5s
//
//.finEvt-relancegroup-ok-show
//	height: 62px
//
//

.finEvt-relancegroup-ok button {
	margin-top: 24px; }

@keyframes fadein {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

.finevt-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .4);
	z-index: 150;
	animation: fadein 0.2s linear;

	.finevt-content {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 920px;
		width: 80%;
		max-width: 1080px;
		height: fit-content;
		background-color: #fff;
		border-radius: 10px;
		padding: 24px;
		box-shadow: 0 0.25rem 0.5rem rgba(31, 45, 61, 0.3);
		border: 1px solid rgba(31, 45, 61, 0.2);
		animation: fadein 0.2s linear;
		animation-delay: 1; } }
