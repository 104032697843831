/////////////// MODAL //////////////////////

.modal-lg,
.modal-xl {
	max-width: 940px;
}

.modal-backdrop {
	visibility: hidden !important;
}

.modal {
	padding-right: 0 !important;

	&:after {
		content: "";
		display: block;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
	}
}

////////////////// BADGE ////////////////////

.badge-active {
	background-color: $success;
	color: $white;
}

.txt-badge-hover {
	visibility: hidden;
	transition: all 0.2s;
	opacity: 0;
}

.badge {
	transition: all 0.2s ease !important;
}

/////////////// CARD ////////////////////////

.card {
	transition: all 0.2s ease !important;
	margin-bottom: unset;
}

.card-header-evenement {
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	background-color: transparent;
}

.content-inner-card {
	max-height: 19rem;
}

.card-fluid {
	height: 100%;
}

/////////////////// PASSWORD //////////////

.ReactPasswordStrength {
	border: inherit !important;
	box-sizing: inherit !important;
	color: inherit !important;
	font-family: inherit !important;
	font-size: inherit !important;
}

.ReactPasswordStrength-input {
	font-size: inherit !important;
	padding: 0.75rem 1.25rem !important;
	width: 100% !important;
	border-style: solid;
	border-width: 1px 1px 0px 1px;
	border-color: $light;
}

//////////////// TODO /////////////////////

.todo-header {
	&:hover {
		cursor: pointer;
		color: $success !important;

		h6 {
			cursor: pointer;
			color: $success !important;
		}
	}
}

/////////////////// DATE PICKER ///////////////



.__react_component_tooltip {
	&.type-success {
		color: $white;
		background-color: $success;

		&.place-top:after {
			border-top-color: $success;
			border-top-style: solid;
			border-top-width: 6px;
		}
	}

	&.type-info {
		color: $white;
		background-color: var(--info);

		&.place-top:after {
			border-top-color: var(--info);
			border-top-style: solid;
			border-top-width: 6px;
		}
	}
}

///////////////// REMOTE UI /////////////////

@keyframes bounce {
	from {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.2, 1.2);
	}
	to {
		transform: scale(1, 1);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.remoteUI {
	opacity: 1;
	animation: fadeIn 0.5s;
}

.remoteUI-Loading {
	display: block;
	background-image: var(--loading-logo-animated);
	background-size: cover;
	width: 200px;
	height: 113px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.loading-app-general {
	background-image: var(--loading-logo);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

/////////////////////// DROPZONE //////////////////////////////////

.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: $light;
	border-style: dashed;
	background-color: $white;
	color: $secondary-darker;
	outline: none;
	transition: border 0.24s ease-in-out;
}

/////////////////////// OMNISEARCH /////////////////////////

.omnisearch .omnisearch-suggestions {
	min-height: 0 !important;
}

.omnisearch .doc-page-btn {
	width: 100%;
}

.omnisearch .doc-page-btn:hover {
	background-color: #3232320f;
	border-left: 2px solid var(--primary);
	opacity: 0.9;
}

.omnisearch .selected {
	background-color: #3232320f;
	border-left: 2px solid var(--primary);
	opacity: 0.9;
}

/////////////////////// RGPD ////////////////////////

.rowRgpd {
	height: 70%;
}

////////////////////// FACTURE FOURNISSEUR /////////////////////

.facture {
	display: none;
}
.facture-content table {
	width: 100%;
	height: 100%;
}

.facture-content th {
	background-color: #eee;
	border: 1px solid black;
}

.facture-content td {
	border: 1px solid black;
}

.facture-content td,
.facture-content th {
	height: 24px;
	font-size: 12px !important;
	line-height: 24px;
	vertical-align: middle;
}

.facture-container {
	display: block;
}

.facture-content div {
	display: inline-block;
}

.striped-background-fullwh {
	display: block;
	position: absolute;
	top: -16px;
	left: -16px;
	background-color: black;
	width: 100vw;
	height: 100vh;
	background-size: auto auto;
	background-color: rgba(255, 255, 255, 1);
	background-image: repeating-linear-gradient(
		135deg,
		transparent,
		transparent 10px,
		rgba(239, 239, 239, 1) 10px,
		rgba(239, 239, 239, 1) 20px
	);
}

.tablerow.row-CANCELLED {
	background: linear-gradient(90deg, rgba(255, 0, 0, 0) 45%, rgba(255, 230, 230, 1) 100%);
}
.tablerow.row-MISSING {
	background: linear-gradient(90deg, rgba(255, 0, 0, 0) 45%, rgba(250, 240, 240, 1) 100%);
}

.tablerow.row-WAITING {
	background: linear-gradient(90deg, rgba(255, 0, 0, 0) 45%, rgba(240, 255, 255, 1) 100%);
}

.tablerow.row-DONE {
	background: linear-gradient(90deg, rgba(255, 0, 0, 0) 45%, rgba(240, 255, 240, 1) 100%);
}

/////////////////// AGENDA ET STATISTIQUES /////////////////////:

.chart_loading {
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.65);
	margin: -1.5rem;
	position: absolute;
}
.chart_loading .spinner-border {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: black;
}

.schedule-calendar {
	display: block;
	width: 100%;
	margin-bottom: 1px;
	padding: 2px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: 12px;
}

.schedule-RELANCE {
	background-color: #d68bee;
}
.schedule-VISITE {
	background-color: #b0e864;
}
.schedule-ESSAI {
	background-color: #dce864;
}
.schedule-APPAREILLE {
	background-color: #64b0e8;
	color: white;
}
.schedule-ADAPTATION {
	background-color: #84e864;
}

.line-disabled {
	opacity: 0.6;
}

.overlay-loading {
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.705);
	margin: -1.5rem;
	top: 0px;
	z-index: 100;
	position: absolute;
}
.overlay-loading b {
	position: absolute;
	width: fit-content;
	height: fit-content;
	margin: auto;
	top: calc(50% + 24px);
	bottom: 0;
	left: 0;
	right: 0;
	color: black;
}

.overlay-loading-aw {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	top: 0px;
	z-index: 150;
	position: absolute;

	.overlay-loading-details {
		font-size: 10px;
	}

	.overlay-loading-logo {
		background-image: var(--loading-logo-animated);
		background-repeat: no-repeat;
		background-size: 62px;
		background-position: center;
		width: 62px;
		height: 62px;
		display: block;
	}
	.overlay-loading-progress-container {
		background-color: rgb(212, 212, 212);
		border-radius: 10px;
		height: 6px;
		width: 200px;

		.overlay-loading-progress {
			border-radius: inherit;
			height: inherit;
			background-color: #47c6a9;
			width: 0%;
			transition: all 0.2s ease-in-out;
		}
	}

	.overlay-loading-text {
		font-weight: bold;
	}
}
/*
.overlay-loading-aw .spinner-border {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: black;
}
*/
.overlay-loading .spinner-border {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: black;
}
.ag-mv-title {
	text-transform: uppercase;
	display: block;
	margin-top: 16px;
	font-size: 24px;
	color: black;
	text-align: center;
}

.ag-mv-title .month {
	font-weight: bold;
}

.ag-mv-day-title {
	display: block;
	background-color: #d4d4d4;
	width: 100%;
	height: 56px;
	line-height: 56px;
	text-transform: uppercase;
	padding: 0px 0px 0px 10px;
	font-size: 18px;
}

.ag-mv-day-tasks {
	position: relative;
	display: block;
	background-color: #eee;
	width: 100%;
}

.ag-mv-day-task {
	position: relative;
	height: 46px;
	vertical-align: middle;
	line-height: 46px;
	border-bottom: 1px solid #ccc;
}

.ag-mv-day-task .task-type {
	display: block;
	width: 100px;
	font-size: 14px;
	text-align: center;
	color: white;
	height: inherit;
	position: absolute;
	border-bottom: 1px solid #ccc;
}

.ag-mv-day-task .task-type.RELANCE {
	background-color: #d68bee;
}
.ag-mv-day-task .task-type.VISITE {
	background-color: #b0e864;
}
.ag-mv-day-task .task-type.SUITE_VISITE {
	background-color: #b0e864;
}
.ag-mv-day-task .task-type.ESSAI {
	background-color: #dce864;
}
.ag-mv-day-task .task-type.APPAREILLE {
	background-color: #64b0e8;
	color: white;
}
.ag-mv-day-task .task-type.ADAPTATION {
	background-color: #84e864;
}

.ag-mv-day-task .task-hour {
	position: absolute;
	margin-left: 112px;
	font-size: 12px;
	top: -10px;
}

.ag-mv-day-task .task-title {
	position: absolute;
	margin-left: 112px;
	top: 8px;
}
.ag-mv-day-todo .task-title {
	margin-left: 112px;
	top: 8px;
	font-size: 12px;
}

.ag-mv-day-todo {
	position: relative;
	height: 32px;
	vertical-align: middle;
	line-height: 32px;
	border-bottom: 1px solid #ccc;
}
.ag-mv-day-todo .task-type.todo {
	display: block;
	width: 100px;
	font-size: 14px;
	text-align: center;
	height: inherit;
	position: absolute;
	border-bottom: 1px solid #ccc;
	background-color: #b3b3b3;
	color: white;
}

.ag-mv-day-title-alwayson {
	position: fixed;
	z-index: 100;
	top: 0px;
	left: 0px;
	display: block;
	background-color: #d4d4d4;
	width: 100%;
	height: 56px;
	line-height: 56px;
	text-transform: uppercase;
	padding: 0px 0px 0px 10px;
	font-size: 18px;
	opacity: 0;
	transition: all 0.2s ease-in;
}

.ag-mv-titlebar {
	position: relative;
	display: block;
	width: 100%;
	text-transform: uppercase;
	margin-top: 16px;
	font-size: 24px;
	color: 555;
}

.ag-mv-previous:hover,
.ag-mv-next:hover {
	cursor: pointer;
	color: #64b0e8;
}

.ag-mv-previous,
.ag-mv-next {
	position: absolute;
}

.ag-mv-next {
	top: 0px;
	right: 0px;
}
.ag-mv-previous {
	top: 0px;
	left: 0px;
}

.ant-picker-calendar-mode-switch {
	display: none !important;
}

.loader-overlay {
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.65);
	margin: -1.5rem;
	position: absolute;
	z-index: 2000;
}

.loader-overlay .spinner-border {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: black;
}

///////////////// DOCUMENT ///////////////////////

.document-iframe {
	height: calc(100vh - 300px);
}

.document-iframe-small {
	height: calc(45vh);
}

.topbarNotificationIcon {
	display: block;
	width: 52px;
	height: 52px;
	position: relative;
	cursor: pointer;
	span.badge {
		position: absolute;
		top: 0;
		right: 0;
		padding-left: 8px;
		padding-right: 8px;
		font-weight: bold;
		font-size: 14px;
	}
}

.avoir-equipment {
	opacity: 0.5;
	text-decoration: line-through;
}

.col-esp-hist {
	div {
		font-family: monospace;
	}
	div:nth-child(even) {
		background-color: #f5f5f5;
	}
}

.caisse-hist-esp {
	display: flex;
	justify-content: center;
}

.d-contents {
	display: contents;
}

@for $i from 2 through 12 {
	.grid-#{$i}-columns-auto {
		display: grid;
		grid-template-columns: #{repeat("auto ", $i)};
	}
}
  

.grid-gap-1rem-x {
	gap: 0 1rem;
}

.grid-gap-1rem-y {
	gap: 1rem 0;
}

.tox-statusbar__branding {
	display: none;
}

.juxtalink-error-textarea {
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	word-wrap: break-word;
	text-overflow: ellipsis;
	height: 62px;
}

.btn-ear-left {
	background-color: var(--info) !important;
	color: white !important;
	&:hover {
		background-color: #04a1bd !important;
	}
}
.btn-ear-right {
	background-color: #ff5630 !important;
	color: white !important;
	&:hover {
		background-color: #d64626 !important;
	}
}

.toastify-small-toast {
	width: 230px;
}

.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(230, 230, 230, 0.3) !important;
}
.text-n {
	font-size: 0.975rem;
}

.table-margin-tdtr  {
	td {
		padding-left: 28px !important;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.fa-duotone-secondary-opacity-1 {
    --fa-secondary-opacity: 1;
}

.modal-loading {

	.modal-content {
		min-height: 230px;
	}

	.modal-loading-body {
		margin: 2px;
	}
	
	.modal-loading-message {
		text-align: center;
		font-weight: bold;
		font-size: 18px;
		color: var(--primary);
	}
	
	.modal-loading-spinner {
		text-align: center;
		font-size: 54px;
		color: var(--primary);
		margin-top: -10px;
	}

	.modal-loading-info {
		bottom: 0px;
		left: 0px;
		position: absolute;
		text-align: center;
		font-weight: bold;
		padding: 8px;
		width: 100%;
	}
}

.btn-buttonless {
	border: none;
	background: none;
	cursor: pointer;
}