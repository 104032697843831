
.inventory-product {
	overflow: hidden;
	margin-top: 12px;
	background-color: #fafafa;
	border-radius: 5px;
	width: 100%;
	border: 1px solid #dfdfdf;
	padding: 4px;
	input {
		height: 28px;
		text-align: center; } }
// .inventory-product-size

.inventory-product-size-name {
	font-weight: bold; }

.inventory-lineserial {
	margin-bottom: 6px;
	height: 32px;
	border-radius: 5px;
	margin-left: 20px;
	&:nth-child(even) {
		background: #f0f0f0; } }

.inventory-textarea {
	width: 100%;
	border-radius: 5px;
	height: 120px;
	padding: 12px;
	color: black;
	font-family: monospace;
	font-size: 14px; }
