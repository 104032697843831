.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 35px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  height: 30px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

#react-switch-text-yes, #react-switch-text-no{
  color: #fff;
}

#react-switch-text-yes {
  margin-left: 10px
}

#react-switch-text-no {
  margin-left: 60px
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

#attendant-patients-number{
  color: darkgrey;
  font-style: italic;
}

.button-delete-attendant{
  display: flex;
  justify-content: center;
  width: 100%;
  padding : 2.5em 0 2.5em 0;
}
