@keyframes scalerot {
	0% {
		transform: scale(.7) translateY(0%);
		opacity: 0; }
	100% {
		transform: scale(1) translateY(-50%);
		opacity: 1; } }

@keyframes scalerot-inverse {
	0% {
		transform: scale(1) translateY(-50%);
		opacity: 1; }
	100% {
		transform: scale(.8) translateY(0%);
		opacity: 0; } }

@keyframes fadein {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes fadeout {
	100% {
		opacity: 0; }
	0% {
		opacity: 1; } }

.modal-content-hide {
	animation: scalerot-inverse .2s ease-in !important;
	transform: scale(.8);
	opacity: 0; }

.modal-hidest {
	animation: fadeout .2s ease-in !important;
	opacity: 0; }

.appointmentChooser {
	position: fixed;
	top: 0px;
	left: 0px;
	display: block;
	width: 99%;
	height: 99%;
	z-index: 130;
	animation: fadein .2s ease-in;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	transition: all .2s linear;
	> .content {
		position: relative;
		display: block;
		top: 50%;
		margin-left: auto;
		margin-right: auto;
		width: 730px;
		height: 630px;
		max-height: 100%;
		background-color: white;
		border-radius: 10px;
		box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
		padding: 12px;
		text-align: center;
		animation: scalerot .2s ease-in;
		transform: translateY(-50%);

		.title {
			font-size: 22px;
			font-weight: bold;
			text-align: center;
			border-bottom: 2px solid rgb(200, 200, 200);
			margin-bottom: 16px; }

		.nextrdv {
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			margin-top: 32px;
			margin-bottom: 26px; } } }
