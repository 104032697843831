.evt-options {
	position: absolute;
	display: block;
	height: 16px !important;
	width: 16px !important;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
}
.evt-label {
	margin-left: 22px;
}
.ag-wv-event-type.RELANCE,
.evt-options.RELANCE {
	background-color: #d68bee;
}
.ag-wv-event-type.TODO,
.evt-options.TODO {
	background-color: #bbb;
}
.ag-wv-event-type.VISITE,
.evt-options.VISITE,
.ag-wv-event-type.PREMIERE_VISITE,
.evt-options.PREMIERE_VISITE {
	background-color: #b0e864;
}
.ag-wv-event-type.SUITE_VISITE,
.evt-options.SUITE_VISITE {
	background-color: #b0e864;
}
.ag-wv-event-type.ESSAI,
.evt-options.ESSAI {
	background-color: #dce864;
}
.ag-wv-event-type.APPAREILLE,
.evt-options.APPAREILLE {
	background-color: #64b0e8;
	color: white;
}
.ag-wv-event-type.ADAPTATION,
.evt-options.ADAPTATION {
	background-color: #84e864;
}

.ag-wv-event-type.NETTOYAGE,
.evt-options.NETTOYAGE {
	background-color: #89c8f5;
}

.evt-modal {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(220, 220, 220, 0.65);
	z-index: 800;
}

.evt-header {
	font-size: 16px;
	padding-bottom: 8px;
	border-bottom: 1px solid #bbb;
	margin-bottom: 24px;
	text-align: center;
	font-weight: bold;
	/*background-color: #eee;
  border-radius: 5px 5px 0px 0px;
  height: 36px;
  line-height: 36px;
  vertical-align: middle;*/
}

.date-picker {
	text-align: center;
}

.evt-container {
	width: 60%;
	height: 50%;
	background-color: rgb(255, 255, 255);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 10px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
}
@media screen and (max-width: 400px) {
	.evt-container {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
	}
}

.evt-container > div,
.evt-end {
	margin-top: 16px;
}

.evt-container input,
.evt-container span.input-group-text {
	height: 32px !important;
}

.evt-container span.input-group-text {
	font-size: 14px;
	font-weight: bold;
	color: #909090;
	min-width: 95px;
}

.ant-select > span[aria-live="polite"] {
	display: none !important;
}
