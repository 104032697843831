@media print {
	#DivSavPrint {
		display: block !important;
		padding: 100px 100px 100px 100px;
		margin-left: 0.5rem !important;
	}
}

#sav-formulaire {
	#sav-select-formulaire {
		border-radius: 5px !important;
	}

	.sav-option-formulaire {
		border-color: #159b80 !important;
	}

	#input-date-sav {
		width: 100%;
	}

	textarea {
		border-radius: 5px;
		width: 100%;
		border-color: #159b80;
		&:focus {
			caret-color: #159b80;
		}
	}

	.button-print-sav {
		width: 100%;
		cursor: pointer;
	}
}

.bold-span {
	font-weight: bold;
	color: #159b80;
}

#DivSavPrint {
	display: none;
}

// Composant d'impression
.table-sav {
	margin-left: auto;
}

.table-col-sav {
	border-style: "solid";
	border-width: 1;
	border-left-width: 0;
	border-top-width: 0;
	padding: "2px";
	font-size: "10px"
}

.table-cell-sav {
	margin-top: "5px";
	padding: "10px";
	border-style: "solid";
	border-width: 1;
	font-size: "16px";
	color: "black";
	padding: "5px"
}

.text-print-sav {
	color: "black";
	font-size: "20px";
	margin: "2px"
}

.footer-container-sav {
	color: "black";
	font-size: 10;
	position: "absolute";
	bottom: "0";
	text-align: "center"
}