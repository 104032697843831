
div.ant-select-dropdown.antd-bootstrapped-select {
	border-radius: 5px !important;
}

.antd-bootstrapped-select {
	// Dropdown of AntD Select
	div.ant-select-dropdown, div.rc-virtual-list-holder-inner, div.rc-virtual-list-holder {
		border-radius: 5px !important;
	}
	div.rc-virtual-list {
		border-radius: 5px !important;
	}

	div.ant-select-item {
		&.ant-select-item-option {
			color: var(--dark);
			border-bottom: 1px solid rgb(215, 215, 215);
			&.ant-select-item-option-active {
				color: var(--primary);
				background-color: var(--secondary);
				border-bottom: 1px solid var(--primary);
			}
		}
	}

	// Select
	> div {
		width: 100%;
	}

	div.ant-select-selector {
		display: block !important;
		width: 100% !important;
		height: calc(1.5em + 1.5rem + 2px) !important;
		padding: 0.75rem 1.25rem !important;
		font-size: 1rem !important;
		font-weight: 400 !important;
		line-height: 1.5 !important;
		color: #8492a6 !important;
		background-color: #fff !important;
		background-clip: padding-box !important;
		border: 1px solid #e0e6ed !important;
		border-radius: 0.25rem !important;
		box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075) !important;
		transition: all 0.2s ease !important;
		.ant-select-selection-placeholder {
			position: absolute !important;
			top: 4px;
		}
		input {
			position: absolute !important;
			top: 4px;
		}
		.ant-select-selection-item {
			position: absolute !important;
			top: 4px;
		}
	}

	&.bootstrapped-sm {
		div.ant-select-selector
		{
			height: calc(1.5em + 1rem + 2px) !important;
			padding: 0.5rem 1.25rem !important;
			font-size: 0.875rem !important;
			line-height: 1.5 !important;
			border-radius: 0.2rem !important;
			width: 100% !important;
		}
	}
}