.custom-antd-table {
	//td
	//	border: 1px solid red !important
	.ant-table-cell {
 }		//border-radius: 10px !important

	.ant-pagination {
		li, button {
			border-radius: 50% !important; } } }


.laboratory-attendance-selector {
	div[class*="Cell-dayView-"], p[class*="Cell-dayOfWeek-"] {
		text-transform: uppercase;
		font-size: 20px !important; }
	div[class*='Cell-dayOfMonth-'] {
		display: none !important; }
	[class*="makeStyles-appointment-"] {
		&:hover {
			background-color: #db4624; } } }
.laboratory-attendance-appointment {
	position: relative;

	&::after {
		position: absolute;
		top: 0px;
		right: 8px;
		font-size: 14px;
		color: white;
		content: "x"; } }

