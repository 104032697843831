.app-loading-bar {
	position: absolute;
	display: block;
	border-radius: 5px;
	height: 4px;
	width: 100%;
	background: var(--primary-half-transparent);
	z-index: 1000;
	bottom: 0;
	overflow: hidden;
	> div {
		display: block;
		height: 4px;
		transition: all .2s linear;
		background: var(--primary); } }
