.custom-topbar-pill {
	.media-pill {
		width: fit-content;
		padding-left: 15px;
		padding-right: 15px;
		max-width: 300px; }
	.media {
		height: 40px; } }


.dropdown-subtitle {
	padding: 0px;
	margin: 0px;
	margin-top: 6px; }

.dropdown-subel {
	margin-left: 6px;
	padding-left: 6px;
	border-left: 1px solid var(--primary-light);
	a:hover {
		color: var(--primary-light) !important;
		padding-left: 6px; }
	.active {
		color: var(--primary-light) !important;
		padding-left: 6px; } }
