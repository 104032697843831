///////////// BUTTON RESELECT /////////////////////

.selected-before {
	background-color: var(--primary-light) !important;

	&:hover {
		background-color: var(--primary-light) !important;
	}
}

.split-color {
	&:hover {
		background: none;
	}

	&.active {
		border: none !important;

		&.left {
			background: linear-gradient(to right, $secondary-darker 50%, $blue 50%);
		}

		&.right {
			background: linear-gradient(to right, $red 50%, $secondary-darker 50%);
		}

		&.both {
			background: linear-gradient(to right, $red 50%, $blue 50%);
		}
	}

	&.selected-before {
		color: #959595;

		&.left {
			background: linear-gradient(to right, $white 50%, $blue-light 50%);
			border-color: $blue;
		}

		&.right {
			background: linear-gradient(to right, $red-light 50%, $white 50%);
			border-color: $red;
		}

		&.both {
			background: linear-gradient(to right, $red-light 50%, $blue-light 50%);
			border: none;
		}
	}
}

/////////////////// BUTTON TOOLTIP ///////////////////////

.button-tooltip {
	z-index: 1000;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);
	transition: transform 0.25s, opacity 0.25s;
	position: absolute;

	button {
		padding: 0.65rem !important;
	}

	&.opened {
		opacity: 1;
		transform: scale(1) translate(0, 0);
	}

	&.closed {
		opacity: 0;
		transform: scale(0.75) translate(0, 25px);
	}
}

.tooltip-selected {
	border-color: var(--primary) !important;
	background-color: var(--primary) !important;
	color: $white !important;
}

///////////////////// BUTTON WITH ARROW //////////////////////

.btn-circle {
	width: 100px;
	height: 100px;
	padding: 10px 16px;
	border-radius: 50px;
	font-size: 24px;
	line-height: 1.33;
}

.arrow-box:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	border-color: rgba(194, 225, 245, 0);
	border-top-color: var(--info);
	border-width: 36px;
	margin-left: -36px;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

////////////////////////// GENERAL //////////////////////////////

.contentButton {
	line-height: normal;
}

.btn-aw {
	width: 200px !important;
	height: 200px !important;
	padding-top: 10%;
}

.btn-block-custom {
	width: 90% !important;
	height: 80% !important;
	font-size: 80%;
}

.btn-nav-custom {
	min-width: 160px !important;
}

.btn-block-withHeight-custom {
	width: 120% !important;
	height: 80% !important;
}

.btn-block-pathology-custom {
	width: 180px !important;
	height: 90px !important;
}

.btn-block-withoutHeight-custom {
	width: 155px !important;
}

.cs-hourselect {
	width: 86px;
	height: 64px;
}

.cs-sq-btn {
	height: 142px;
	padding: 10px;
}

.btn-outline-primary:not(:disabled):not(.disabled):hover {
	background-color: var(--primary-light);
	border-color: var(--primary-light);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:hover {
	background-color: $success;
	border-color: $success;
}

.cs-container-btn i {
	position: absolute;
}

.cs-btn-whauto {
	position: absolute !important;
	width: 100% !important;
	top: 50% !important;
	transform: translateY(-50%) !important;
}

.cs-container-btn {
	display: block !important;
	width: 100% !important;
	margin: 6px 0px 16px 0px !important;
}

.cs-salleattente-btn {
	min-height: 98px;
	margin-bottom: 14px;
	font-size: 1.5rem !important;
	height: 100%;
}

.cs-creation-inputs input,
button.cs-creation-inputs {
	height: 64px;
	font-size: 20px;
}

.mt-4,
.row {
	.button-situation-container {
		display: flex;
		justify-content: space-around;
		align-content: center;
		align-items: center;
		width: 100%;
		flex-wrap: wrap;
	}
}

// For phone based on IPhoneX
$breakpoint-phone-min: 320px;
$breakpoint-phone-max: 568px;
@media only screen and (min-width: $breakpoint-phone-min) and (orientation: portrait) {
	.mt-4,
	.row {
		.button-situation-container {
			flex-direction: column;
			width: 100%;
			padding: 2vh 0 0 0;

			.situationsButton {
				height: 10vh;
				width: 40vw !important;
				font-size: 1rem;
			}
		}
	}
}

@media only screen and (min-width: $breakpoint-phone-max) and (orientation: landscape) {
	.mt-4,
	.row {
		.button-situation-container {
			flex-direction: row;
			justify-content: space-around;
			padding: 1vh 0 0 0;

			.situationsButton {
				height: 25vh;
				width: 30vw !important;
				font-size: 2.75rem;
			}
		}
	}

	.premiere-visite-title,
	.premiere-visite-header {
		font-size: 1.75rem;
	}
}

// For tablet based on IPad
$breakpoint-ipad-min: 768px;
$breakpoint-ipad-max: 1024px;
@media (min-width: $breakpoint-ipad-min) and (orientation: portrait) {
	.mt-4,
	.row {
		.button-situation-container {
			flex-direction: row;
			justify-content: space-around;
			padding: 5vh 0 0 0;

			.situationsButton {
				height: 20vh;
				width: 30vw !important;
				font-size: 5rem;
			}
		}
	}

	// .premiere-visite-title,
	// .premiere-visite-header {
	// 	font-size: 4rem !important;
	// }
}

@media only screen and (min-width: $breakpoint-ipad-max) and (orientation: landscape) {
	.mt-4,
	.row {
		.button-situation-container {
			flex-direction: row;
			justify-content: space-around;
			padding: 10vh 0 0 0;

			.situationsButton {
				height: 30vh;
				width: 35vw !important;
				font-size: 5rem;
			}
		}
	}

	.premiere-visite-title,
	.premiere-visite-header {
		font-size: 3rem;
	}
}

// For tablet based on IPad Pro
$breakpoint-ipad-pro-min: 1024px;
$breakpoint-ipad-pro-max: 1366px;
@media only screen and (min-width: $breakpoint-ipad-pro-min) and (orientation: portrait) {
	.mt-4,
	.row {
		.button-situation-container {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-content: center;
			align-items: center;
			width: 100%;
			padding: 10vh 0 0 0;
			flex-wrap: wrap;

			.situationsButton {
				height: 25vh;
				width: 35vw !important;
				font-size: 5rem;
			}
		}
	}

	.premiere-visite-title,
	.premiere-visite-header {
		font-size: 4rem;
	}
}

@media only screen and (min-width: $breakpoint-ipad-pro-max) and (orientation: landscape) {
	.mt-4,
	.row {
		.button-situation-container {
			flex-direction: row;
			justify-content: space-around;
			padding: 10vh 0 0 0;

			.situationsButton {
				height: 25vh;
				width: 35vw !important;
				font-size: 5rem;
			}
		}
	}

	.premiere-visite-title,
	.premiere-visite-header {
		font-size: 4rem;
	}
}

// For laptops
$breakpoint-laptop-min: 1367px;
@media only screen and (min-width: $breakpoint-laptop-min) {
	.mt-4,
	.row {
		.button-situation-container {
			flex-direction: row;
			justify-content: space-around;
			padding: 10vh 0 0 0;

			.situationsButton {
				height: 25vh;
				width: 30vw !important;
				font-size: 3rem;
			}
		}
	}

	.premiere-visite-title,
	.premiere-visite-header {
		font-size: 2rem;
	}
}

.cs-btn {
	cursor: pointer;
}

.rgpd-btn {
	min-height: 100px;
}
