.spotlight {
	input {
		border-radius: 15px;
		border: none;
		background-color: rgba(255, 255, 255, 0.678);
		transition: .1s ease-in-out all;
		width: 0px;
		display: none;

		@media (min-width: 1024px) {
			width: 360px;
			display: block;
		}

		padding-left: 26px;
		padding-right: 12px;
		&:active , &:focus {
			background-color: rgb(255, 255, 255);
		}

		font-size: 14px;
	}

	.spotlight-input-group {
		.prepend {
			position: absolute;
			font-size: 18px;
			top: 4px;
			left: 6px;
		}
	}

	.category-name {
		color: var(--primary);
		font-size: 14px;
		margin-bottom: 4px;
		font-weight: bold;
	}

	.birthdate {
		font-size: 12px;
	}

	&:hover {
		opacity:1;
	}

	.result {
		position: absolute;
		visibility: hidden;
		left: 0px;
		margin-top: 18px;
		min-height: 500px;
		max-height: 90%;
		min-width: 460px;
		// left: 50%;
		// transform: translate(-50%, 0);
		background-color: rgb(255, 255, 255);
		border-radius: 0px 0px 15px 15px;
		padding: 10px;
		box-shadow: 0px 10px 115px 0px rgba(0, 0, 0, 0.329);
		overflow: auto;
		font-size: 14px;

		.item {
			display: flex;
			border-radius: 5px;
			padding-top: 3px;
			padding-bottom: 3px;
			color: var(--gray-dark);
			border-bottom: 1px solid rgba(128, 128, 128, 0.158);
		}

		a.item {
			cursor: pointer;
			&:hover {
				background-color: rgba(0, 0, 0, .03);
				color: var(--primary);
			}
		}

	}
}