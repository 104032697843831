.p-settings {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    .schedules-type {
        display: flex;
        margin: 5px;
        //background-color: #ededed
        min-width: 250px;
        min-width: 32%;
        border-radius: 5px;
        //flex-grow: 1
        flex-grow: 0;
        position: relative;
        //justify-content: center
        //align-items: center
        border: 1px solid #bbbbbb;
        box-shadow: 2px 2px 5px 1px #00000022;
        //justify-content: space-around
        //align-content: flex-start
        @media screen and (min-width: 700px) {
            min-width: 45%; }
        @media screen and (min-width: 900px) {
            min-width: 32%; }
        @media screen and (min-width: 1200px) {
            min-width: 24%; }


        &.appDefault {
            .label,.action {
                opacity: .5; } }

        &.hidden {
            .label {
                margin-bottom: 4px;
                font-weight: normal; } }

        .color {
            position: absolute;
            background-color: red;
            height: 100%;
            width: 24px;
            left: 0;
            border-radius: 5px 0px 0px 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            input {
                width: 0px;
                height: 0px;
                margin: 0px;
                padding: 0px;
                border: none;
                visibility: hidden; }
            .color-picker-icon {
                position: relative;
                font-size: 16px;
                color: white;
                --fa-secondary-opacity: 0.5;
                --fa-primary-opacity: 1; } }
        .label {
            margin-bottom: 22px;
            margin-left: 20px;
            text-align: left;
            font-weight: bold;
            font-size: 16px;
            color: #555;
            width: 80%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            input {
                width: 100%;
                height: 23px;
                border: none;
                padding: 0px;
                text-align: left; } }
        .action {
            position: absolute;
            margin-left: 20px;
            bottom: 0px;
            text-align: left;
            //background-color: #edededed
            font-size: 12px;
            border-radius: 0px 0px 5px 5px;
            color: #999; }

        .timerange {
            position: absolute;
            margin-left: 20px;
            bottom: 0px;
            text-align: right;
            right: 6px;
            //background-color: #edededed
            font-size: 12px;
            border-radius: 0px 0px 5px 5px;
            color: #999;
            input {
                width: 62px;
                text-align: center;
                padding: 0px;
                margin: 0px;
                font-size: 14px;
                border: 0px; } }


        .archive {
            position: absolute;
            top: 0px;
            right: 4px;
            font-size: 20px; }
        .delete {
            position: absolute;
            top: -4px;
            right: 4px;
            color: red;
            font-size: 18px; } } }
