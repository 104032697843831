
.agenda {
	height: calc(100% - 95px) !important; }

.scheduling-part {
	display: flex;
	align-items: center;
	height: 38px;
	padding-left: 10px;
	margin-bottom: 12px;
	.scheduling-title {
		height: 38px;
		background-color: rgb(240, 240, 240);
		border-radius: 5px;
		font-size: 16px;
		font-weight: bold;
		line-height: 38px;
		vertical-align: middle;
		color: black; } }

.schedule-line-option {
	display: flex;
	flex-direction: row;
	align-items: center;
	.option-color {
		display: block;
		background-color: #000;
		width: 20px;
		height: 20px;
		border-radius: 5px;
		margin-right: 6px; } }

.modal-agenda {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(220, 220, 220, 0.8);
	animation: fadein .2s ease-in;
	z-index: 800;

	.container {
		box-sizing: content-box;
		width: 80%;
		min-width: 520px;
		height: 720px;
		max-height: 100vh;
		//background-color: rgb(255, 255, 255)
		background-color: rgb(248, 248, 251);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0px;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 5px;
		border: 1px solid #eee;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
		// transition: all .5s
		// animation: scalerot .2s ease-in

		.title {
			position: relative;
			font-size: 20px;
			line-height: 52px;
			height: 52px;
			vertical-align: middle;
			background-color: rgb(240, 240, 240);
			border-bottom: 1px solid #dfdfdf;
			border-radius: 5px 5px 0px 0px;
			width: 100%;
			text-align: center;
			text-transform: uppercase;

			span {
				position: absolute;
				float: right;
				right: 12px;
				border-radius: 5px;
				padding-left: 5px;
				padding-right: 5px;
				height: 34px;
				vertical-align: middle;
				line-height: 34px;
				font-size: 16px;
				color: white;
				margin-top: 12px;
				cursor: pointer; }

			span.close-btn {
				background-color: #ff9999;
				margin-right: 6px; }

			span.full-btn {
				background-color: #99ceff;
				margin-right: 96px; } }

		.title.bigger {
			height: 96px; }

		.menu-bar {
			display: flex;
			margin-top: -6px;

			.select-container {
				flex-grow: 1;
				height: 36px;
				svg {
					position: absolute;
					height: 20px;
					width: 20px;
					margin-top: 16px;
					margin-left: 16px; }
				select {
					height: 36px;
					width: 95%;
					padding-left: 32px;
					font-size: 16px;
					background-color: #ffffff;
					border: 1px solid #aaa;
					border-radius: 5px;
					margin-left: 8px;
					margin-right: 8px; } } }

		.content {
			//position: relative
			padding: 10px;
			padding-bottom: 52px;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden; }

		img {
			width: 100%;
			height: 100%; } }

	.full-screen {
		width: 100%;
		min-width: 100%;
		height: 100vh; } }

.modal-agenda {
	.container.small {
		width: fit-content;
		height: 400px; } }

td[class*='DONE_'], .GREYED {
		background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.12) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.12) 50%, rgba(0, 0, 0, 0.12) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%) !important;
		background-size: 14.14px 14.14px !important; }

tr > td {
	transition: background-color .4s ease-in; }

td.SELECTION {
	opacity: .7;
	border: none;
	border-left: 2px solid #4f9966;
	border-right: 2px solid #4f9966; }

.active-touch {
	background-color: red !important; }

.btn-touchContainer {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1100;
	bottom: 0;
	right: 0;
	height: 72px;
	background-color: rgb(240, 240, 240);
	width: 100%;
	border-top: 1px solid #dfdfdf;
	transition: all .4s ease-in-out;
	opacity: 1;
	overflow: hidden;

	div.content-details {
		margin-left: 24px; }

	div.btn-container {
		div {
			display: inline-block;
			width: fit-content;
			border-radius: 5px;
			height: 42px;
			color: white;
			line-height: 42px;
			vertical-align: middle;
			text-align: center;
			padding-left: 15px;
			padding-right: 15px;
			text-transform: uppercase;
			font-size: 16px;
			margin-right: 24px;
			transition: all .1s linear; }

		div.btn-clearSelection {
			background-color: #ff9999; }

		div.btn-validateSelection {
			background-color: #59da99; }

		div.btn-clearSelection:active {
			background-color: #f07474;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); }

		div.btn-validateSelection:active {
			background-color: #409e6f;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); } } }

.btn-touchContainer.hide {
	opacity: 0;
	z-index: 0;
	height: 0; }

$color_save: #59da99;
$color_cancel: #ff9999;

//////////////////////:::: Addnew SHECULE

$color_premiereVisite: #b2ec61;
$color_suiteVisite: $color_premiereVisite;
$color_essaie: #dce864;
$color_controle: #64b0e8;
$color_adaptation: #84e864;
$color_nettoyage: #89c8f5;

.evt-btn-container, .evt-state-container {
	width: 100%;
	flex-direction: row;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap; }
.type-btn {
	height: 46px;
	white-space: normal;
	padding-top: 8px;
	padding-bottom: 8px;
	margin: 6px;
	border-radius: 5px;
	text-transform: uppercase;
	width: 188px;
	text-align: center;
	display: flex;
	font-size: 18px;
	transition: all .5s ease-out;
	background-size: 0%;
	background-position: center;
	cursor: pointer;
	flex-grow: 1;
	padding-left: 4px;
	padding-right: 4px;
	font-size: 1.0rem;
	align-items: center;
	justify-content: center;
	word-wrap: break-word;
	&.disabled {
		box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
		color: #bababa;
		background-color: #efefef; } }

@mixin bg-handler($color) {
	background: $color radial-gradient(circle, transparent 1%, $color 1%) center/15000%; }

.premiereVisite, .VISITE, .PREMIERE_VISITE {
	background-color: white;
	color: $color_premiereVisite;
	box-shadow: inset 0px 0px 1px 1px $color_premiereVisite;
	&.active {
		@include bg-handler($color_premiereVisite); } }

.suiteVisite, .SUITE_VISITE {
	background-color: white;
	color: $color_suiteVisite;
	box-shadow: inset 0px 0px 1px 1px $color_suiteVisite;
	&.active {
		@include bg-handler($color_suiteVisite); } }
.essaie, .ESSAI {
	background-color: white;
	color: $color_essaie;
	box-shadow: inset 0px 0px 1px 1px $color_essaie;
	border: none !important;
	&.active {
		@include bg-handler($color_essaie); } }

.adaptation, .ADAPTATION {
	background-color: white;
	color: $color_adaptation;
	box-shadow: inset 0px 0px 1px 1px $color_adaptation;
	&.active {
		@include bg-handler($color_adaptation); } }

.controle, .CONTROLE, .APPAREILLE {
	background-color: white;
	color: $color_controle;
	box-shadow: inset 0px 0px 1px 1px $color_controle;
	&.active {
		@include bg-handler($color_controle); } }


$color_done: #73ec73;
$color_missing: #9cc3fd;
$color_cancelled: #e67979;

@mixin btn-style($color) {
	background-color: white;
	color: $color;
	box-shadow: inset 0px 0px 1px 1px $color;
	&.active {
		@include bg-handler($color); } }

.done {
	@include btn-style($color_done); }
.missing {
	@include btn-style($color_missing); }
.cancelled {
	@include btn-style($color_cancelled); }

.active {
	font-weight: bold;
	color: white;
	background-size: 100%; }

.evt-txtarea {
	border-radius: 5px;
	width: 100%;
	padding: 8px; }

.evt-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: red;
	position: absolute;
	bottom: 0px;
	left: 0;
	width: 100%;
	height: 52px;
	line-height: calc(52px);
	vertical-align: middle;
	background-color: rgb(240, 240, 240);
	border-top: 1px solid #dfdfdf;
	padding-left: 6px;
	padding-right: 6px;
	button {
		width: 50%;
 } }		//flex-grow: 1

.cs-btn {
	height: 36px;
	line-height: calc(36px);
	vertical-align: middle;
	border-radius: 5px;
	text-transform: uppercase;
	min-width: 200px;
	text-align: center;
	display: inline-block;
	font-size: 18px;
	transition: all .5s ease-out;
	color: #ffffff;
	padding-left: 22px;
	padding-right: 22px;
	margin-left: 12px;
	margin-right: 12px;
	border: 1px solid white; }

.btn-cancel {
	background-color: $color_cancel; }

.btn-save {
	background-color: $color_save; }

.modal-agenda {
	.container {
		width: 825px;
		max-width: 1280px;
		.content.newSchedule {
			padding-bottom: 56px;

			h5 {
				margin-top: 12px;
				background-color: rgb(240, 240, 240);
				border-radius: 5px;
				padding: 5px 5px 5px 15px; } }
		.evt-patient-container {
			height: 36px;
			width: 100%;
			position: relative;
			.patient-name {
				position: absolute;
				top: 0;
				font-weight: bold;
				font-size: 20px;
				margin-left: 16px;
				left: 0; }
			.sl-btn {
				position: absolute;
				top: 0;
				margin-right: 16px;
				right: 0;
				text-transform: none;
				background-color: rgb(230, 230, 255);
				border: 1px solid rgb(200, 200, 255);
				color: rgb(120, 120, 180); } }
		.evt-date-container {
			.dayDate {
				font-size: 20px;
				font-weight: bold; }
			.monthName {
				font-size: 18px;
				font-weight: bold; }
			.hour {
				font-size: 18px;
				font-weight: bold; }
			.minutes {
				font-size: 14px;
				font-weight: bold; } }
		.evt-notes-container {
			textarea {
				outline: none; } } } }

.sl-btn {
	height: 36px;
	line-height: calc(36px);
	vertical-align: middle;
	border-radius: 5px;
	text-transform: uppercase;
	min-width: 200px;
	text-align: center;
	display: inline-block;
	font-size: 18px;
	transition: all .5s ease-out;
	color: #000;
	padding-left: 22px;
	padding-right: 22px;
	border: 1px solid black; }

.agenda-item {
	font-size: 14px;
	overflow: hidden;
	flex-direction: column;
	display: flex;
	padding: 2px;
	position: absolute;
	background: #fbfbfb;
	margin: 0px;
	margin-left: 2px;
	line-height: 1.2;
	transition: width .3s;
	transition-timing-function: ease-in-out;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	background-color: rgba(255, 255, 255, 0.7);
	span {
		color: black;
		padding: 6px;
		font-size: 12px;
		text-transform: none; } }

.tag {
	font-size: 14px;
	height: 26px;
	line-height: 26px;
	vertical-align: middle;
	border: 1px solid #000000;
	box-shadow: none !important;
	width: 100%;
	text-align: center; }

.tag.PREMIERE_VISITE {
	color: darken($color_premiereVisite, 15);
	border-color: darken($color_premiereVisite, 15); }

.tag.SUITE_VISITE {
	color: darken($color_suiteVisite, 15);
	border-color: darken($color_suiteVisite, 15); }

.tag.VISITE {
	color: darken($color_suiteVisite, 15);
	border-color: darken($color_suiteVisite, 15); }

.tag.ESSAI {
	color: darken($color_essaie, 15);
	border-color: darken($color_essaie, 15); }

.tag.APPAREILLE {
	color: darken($color_controle, 15);
	border-color: darken($color_controle, 15); }

.tag.ADAPTATION {
	color: darken($color_adaptation, 15);
	border-color: darken($color_adaptation, 15); }

.tag.MISSING {
	color: darken(rgba(250, 240, 240, 1), 50);
	border-color: darken(rgba(250, 240, 240, 1), 50);
	background-color: rgba(250, 240, 240, 1); }

.tag.DONE {
	color: darken(rgba(240, 255, 240, 1), 50);
	border-color: darken(rgba(240, 255, 240, 1), 50);
	background-color: rgba(240, 255, 240, 1); }

.tag.WAITING {
	color: darken(rgba(240, 255, 255, 1), 50);
	border-color: darken(rgba(240, 255, 255, 1), 50);
	background-color: rgba(240, 255, 255, 1); }

.tag.CANCELLED {
	color: darken(rgba(255,230,230, 1), 50);
	border-color: darken(rgba(255,230,230, 1), 50);
	background-color: rgba(255,230,230, 1); }

.agenda-appointment-tooltip {
	position: relative;
	border-left: 4px solid #eee;
	padding: 6px; }

.agenda-appointment-tooltip-infos {
	padding: 6px;
	.patient-supl-data {
		font-size: 14px;
		display: flex;
		justify-content: space-between; }
	.patient-name {
		font-size: 18px;
		font-weight: bold; }
	.appointment-type {
		font-size: 16px;
		font-weight: bold; }
	.appointment-date {
		font-size: 14px; }
	.appointment-note {
		border-left: 4px solid #ddd;
		padding-left: 6px;
		font-size: 14px;
		border-radius: 5px; } }

div[class*="Layout-popover-"] {
		box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2);
		width: 300px;
		border: 1px solid #d1d1d1; }

.agenda-appointment-tooltip-icons {
	width: 100%;
	position: relative;
	.appointment-action {
		position: relative;
		display: block;
		height: 36px;
		border-radius: 5px;
		transition: all .1s ease-in;
		&:hover {
			cursor: pointer;
			background-color: #eee; }
		> span {
			position: absolute;
			vertical-align: middle;
			line-height: 36px;
			left: 44px; }
		> svg {
			position: absolute;
			top: 8px;
			left: 8px;
			height: 22px; } } }
