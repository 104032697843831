.product {
  .ant-switch {
    height: 46px;
    border-radius: 5px;
    width: 100%;
    &.ant-switch-checked {
      background-color: var(--primary); } } }

.product {
    .ant-switch-handle {
      border-radius: 5px;
      height: 42px; } }

.product {
  .ant-switch-inner {
    font-size: 14px; } }
