// Couleurs pour les badges pile
.energy {
    &.energy-piles10 {
      background-color: #e2b10c;
      color: white;
    }
    &.energy-piles13 {
      background-color: #cd7200;
      color: white;
    }
    &.energy-piles312 {
      background-color: #652501;
      color: white;
    }
    &.energy-piles675 {
      background-color: #004799;
      color: white;
    }
    &.energy-rechargeable {
      background-color: #abcd4f;
      color: white;
    }
  }

.energy-hoverable {
  &:hover {
    cursor: pointer;
  }
}
