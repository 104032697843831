.step {
	max-width: 30px;
	overflow: visible;
	opacity: 0.4;
	transition: opacity 0.3s;

	&.active {
		opacity: 1;
	}

	.step-text {
		white-space: nowrap;
	}

	.step-circle {
		height: 30px;
		width: 30px;
		background-color: $blue;
	}
}

.step-button,
.copy-button {
	background-color: $blue;
	width: 50px;
	height: 50px;
	border: none;
	transition: transform 0.5s;
	color: $white;
	font-size: 2em;

	&:hover {
		transform: scale(1.03);
	}

	&:disabled {
		transform: none;
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.copy-button {
	margin-bottom: auto;
}

.separator-line-dashed {
	width: 15%;
	margin: 0 20px;
	margin-bottom: 15px;
	background-image: linear-gradient(to right, $dark 33%, white 0%);
	background-position: bottom;
	background-size: 26px 1.5px;
	background-repeat: repeat-x;
}
