.form-select {
	display: block;
	height: calc(1.5em + 1.5rem + 2px);
	padding: 0 10px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: $gray;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $gray-light;
	border-radius: 0.25rem;
	box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075);
	transition: all 0.2s ease;
}

/* Augmente la taille des icônes group dans FieldWithError */
.input-group-text.input-group-icon  {
	font-size: 1.5rem;
}